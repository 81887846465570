.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* 全屏高度 */
    background-color: #f0f0f0;
    /* 背景颜色 */
}

.login-button {
    padding: 15px 30px;
    font-size: 16px;
    color: white;
    background-color: #1a73e8;
    /* 按钮颜色 */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.login-button:hover {
    background-color: #1666c1;
    /* 鼠标悬停时按钮颜色 */
}