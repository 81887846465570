.callback-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

h1 {
    color: #333;
    margin-bottom: 30px;
}

.error {
    color: red;
    margin-bottom: 20px;
}

.user-info {
    background-color: #f3f3f3;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
}

.info-field {
    margin: 10px 0;
    font-size: 18px;
}

.info-field span:first-child {
    font-weight: bold;
}

@media screen and (max-width: 600px) {
    .callback-container {
        padding: 10px;
    }

    .info-field {
        font-size: 16px;
    }
}
